import { FaPhone } from 'react-icons/fa6'
import { IoHome } from 'react-icons/io5'
import { MdEmail } from 'react-icons/md'

import { Link } from 'react-router-dom'
import ExcelencyaLogo from '../logo/ExcelencyaLogo'
import {
  FaFacebookF,
  FaInstagram,
  FaRegHeart,
  FaPinterestP,
} from 'react-icons/fa'

import AnclaSuave from '../../anclaSuave/AnclaSuave'

export default function Footer() {
  const socialLinks = [
    {
      label: 'Facebook',
      icon: FaFacebookF,
      href: 'https://www.facebook.com/Excelencya/',
    },
    {
      label: 'Instagram',
      icon: FaInstagram,
      href: 'https://www.instagram.com/excelencya_eventos/',
    },
    {
      label: 'Bodas.net',
      icon: FaRegHeart,
      href: 'https://www.bodas.net/organizacion-bodas/excelencya--e78458',
    },
    {
      label: 'Pinterest',
      icon: FaPinterestP,
      href: 'https://www.pinterest.es/excelencyaeventos/',
    },
  ]

  const products = [
    { label: 'Bodas', href: '#weddings' },
    { label: 'Eventos Sociales', href: '#socialEvents' },
    { label: 'Eventos Corporativos', href: '#corporateEvents' },
  ]

  // const interestingLinks = [
  //   { label: 'Pricing', href: '/pricing' },
  //   { label: 'Settings', href: '/settings' },
  //   { label: 'Orders', href: '/orders' },
  //   { label: 'Help', href: '/help' },
  // ]

  const contact = [
    { label: 'Sevilla, España', icon: IoHome },
    { label: 'direccion@excelencya.es', icon: MdEmail },
    { label: '+34 619 686 089', icon: FaPhone },
  ]

  return (
    <footer className="bg-primary-light text-center text-neutral-600 xl:text-left xl:px-[20%] md:px-[10%] px-[5%]">
      <div className="flex items-center justify-center border-b-2 border-primary p-6">
        {/* <div className="mr-12 hidden xl:block">
          <span className="font-garamond text-primary text-xl">¡Conecta con nosotros en redes sociales!</span>
        </div> */}
        <div className="flex justify-evenly w-1/2 sm:w-5/6">
          {socialLinks.map((link) => (
            <Link to={link.href} key={`Social link ${link.label}`}>
              <link.icon className="bg-primary-light hover:bg-primary text-primary hover:text-primary-light rounded-full h-[30px] w-[30px] p-1 hover:cursor-pointer transition-all duration-300" />
            </Link>
          ))}
        </div>
      </div>

      <div className="mx-6 py-10 text-center md:text-left">
        <div className="grid-1 grid gap-8 md:grid-cols-3">
          <div className="">
            <h6 className="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
              <ExcelencyaLogo />
            </h6>
          </div>
          <div className="">
            <h6 className="mb-4 uppercase text-primary font-garamond font-bold text-center">
              Productos
            </h6>
            {products.map((product) => (
              <AnclaSuave
                href={product.href}
                key={`Product ${product.label}`}
                className="mb-4 block font-garamond hover:cursor-pointer text-center"
              >
                {product.label}
              </AnclaSuave>
            ))}
          </div>

          {/* <div className="">
            <h6 className="mb-4 flex justify-center font-bold uppercase md:justify-start font-garamond text-primary">
              Links de interés
            </h6>
            {interestingLinks.map((link) => (
              <Link
                to={link.href}
                key={`Link ${link.label}`}
                className="mb-4 block font-garamond"
              >
                {link.label}
              </Link>
            ))}
          </div> */}
          {/* <!-- Contact section --> */}
          <div>
            <h6 className="mb-4 font-semibold uppercase text-primary font-garamond text-center">
              Contacto
            </h6>
            {contact.map((contact) => (
              <p
                key={`Contact ${contact.label}`}
                className="mb-4 flex items-center justify-center font-garamond"
              >
                <contact.icon className="mr-3 h-5 w-5 text-primary" />
                {contact.label}
              </p>
            ))}
          </div>
        </div>
      </div>

      {/* <!--Copyright section--> */}
      <div className="bg-priamry p-6 text-center text-primary font-garamond">
        © {new Date().getFullYear()} Copyright - Excelencya {/*S.L.*/}
      </div>
    </footer>
  )
}
